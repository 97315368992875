import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
// import {KioskModelService} from '../models/kiosk.model.service';

@Injectable()
export default class GlobalErrorHandler implements ErrorHandler {
  // kioskModelService: KioskModelService;

  constructor(
    // kioskModelService: KioskModelService
  ) {
    // this.kioskModelService = kioskModelService;
  }

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message;
  }

  handleError(error: Error | HttpErrorResponse) {
    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      message = this.getServerMessage(error);
      // this.kioskModelService.postLog({
      //   level: 'HTTP Error',
      //   text: message,
      //   isEmail: true,
      //   isBlocked: true
      // });
    } else {
      // Client Error
      message = this.getClientMessage(error);
      stackTrace = this.getClientStack(error);
      console.error(error);
      // this.kioskModelService.postLog({
      //   level: 'Error',
      //   text: message + '\n' + stackTrace,
      //   isEmail: true,
      //   isBlocked: false
      // });
    }

  }
}
